<template>
  <div>
    <div class="technology">
      <div>
        <div><h1>大数据技术 /<span> TECHNOLOGY</span></h1></div>

        <el-row class="row-wrap">
          <div v-for="(item,index) in newslist" :key="index" class="div-wrap">

            <div class="card-wrap" :class="{hovertag:flag == index}" @mouseover="selectStyle(index)" @mouseout="outStyle(item)">
              <img v-if="item.url" :src="require('../../assets/' + item.url)"  class="image">
              <div class="p-wrap">
                <p>{{item.title}}
                  <br>
                  <span>{{item.content}}</span>
                </p>
              </div>
            </div>

          </div>
        </el-row>

      </div>
    </div>
  </div>
</template>

<script>
import {getIntamhwTechnologyList} from '@/api/technology';
export default {
  name: 'technology',
  data(){
    return{
      flag:2,
      newslist:[]
    }
  },
  created(){
    this.getIntamhwTechnologyList();
  },
  methods:{
    getIntamhwTechnologyList(){
      getIntamhwTechnologyList().then(res => {
        if (res.status == 200) {
          this.newslist = res.data;
        } else {
          alert("获取大数据技术异常！");
        }
      })
    },
    selectStyle(index){
      this.flag = index
    },
    outStyle(index){
      // this.flag = -1
    }
  }
}
</script>

<style lang="scss" scoped>
  .hovertag{
    position: absolute;
    top:100px;
    z-index:999;
    height: 450px!important;
    margin-top: -125px;
    border: 1px solid #a3a3a3;
    box-shadow:0 0 15px rgba(0, 0, 0, .6);
    .p-wrap{
      // height: 450px!important;
      p{
        margin-top:-454px!important;
      }

    }
  }

.technology{
  width: 1252px;
  height: 800px;
  margin: 0px auto;
  h1{
    text-align: center;
    font-size: 34px;
    margin-top: 90px;
    margin-bottom: 50px;
    span{
      color: rgb(179, 179, 179);
      font-size: 34px;
      font-weight: 700;
    }
  }
  .row-wrap{
    width: 1252px;
    .div-wrap{
      .card-wrap{
        width: 250px;
        height: 400px;
        position: relative;
        img{
          width: 100%;
          height: 100%;
        }
        .p-wrap{
          position: relative;
          color: #fff;
          width: 100%;
          height: 100%;
          background-color:rgba(0, 0, 0, 0.15);
          margin-top: -200px;
          p{
            padding:0px;
            margin:0px;
            padding-top:80px;
            margin-top:-404px;
            height: 320px;
            text-align: center;
            font-size: 25px;
            span{
              display: inline-block;
              font-size: 16px;
              line-height: 25px;
              text-align: left;
              padding: 5px 20px;
              text-indent: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
