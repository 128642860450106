window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        function( callback ){
            window.setTimeout(callback, 1000 / 60);
        };
})();

let ctx = null;
let points = [];
let w=0;
let h = 0;
export function initPointLine() {
    let canvas = document.getElementById("canvas");
    ctx = canvas.getContext('2d');
    w = canvas.offsetWidth;
    h = canvas.offsetHeight;
    canvas.width = w;
    canvas.height = h;
    for(var i =0;i<60;i++){
        points.push(new Point(Math.random() * w,Math.random()*h ));
    }
}


function Point(x,y){
    this.x = x;
    this.y = y;
    this.r = 3+Math.random() * 2;
    this.sx = Math.random() * 2 - 1
    this.sy = Math.random() * 2 - 1

    this.alpha = Math.random();
}
Point.prototype.move = function(){
    this.x  += this.sx;
    this.y += this.sy;
    if(this.x > w || this.x <0) this.sx = - this.sx;
    if(this.y > h || this.y < 0) this.sy = -this.sy;
}
Point.prototype.draw = function(ctx){
    ctx.beginPath();
    ctx.arc(this.x,this.y,this.r,0,2*Math.PI);
    ctx.closePath();

    ctx.fillStyle = 'rgba(10, 106, 171, ' + this.alpha + ')';
    ctx.fill();
}
Point.prototype.drawLine = function(ctx,p){
    var length = 70;
    var dx = this.x - p.x;
    var dy = this.y - p.y;
    var d = Math.sqrt(dx*dx +dy *dy);
    if(d < length){
        var alpha = (length - d )/ 100;
        ctx.beginPath;
        ctx.moveTo(this.x,this.y);
        ctx.lineTo(p.x,p.y);
        ctx.closePath();
        ctx.strokeStyle = 'rgba(10, 106, 171, ' + alpha + ')'
        ctx.strokeWidth=1;
        ctx.stroke();
    }
}


export function loop(){
    requestAnimFrame(loop);
    paint();
}


function paint(){
    ctx.clearRect(0,0,w,h);
    for(var i = 0;i<points.length;i++){
        points[i].move();
        points[i].draw(ctx);
        for(var j = i+1;j< points.length;j++){
            points[i].drawLine(ctx,points[j]);
        }

    }
}
