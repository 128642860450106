<template>
  <div class="home">
    <div class="index">
      <!-- 公司简介 -->
      <div class="one">
        <div class="one_B">
          <div class="one_lift wow animate__animated animate__fadeInLeft">
            <p class="name">{{ companyContent.title }}</p>
            <p class="sdp" v-html="companyContent.content"></p>
          </div>
          <div
            @click="toVideo"
            class="one_right wow animate__animated animate__fadeInRight"
          >
            <div class="btn"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 品牌定位 -->
    <div class="brand" id="ytpp">
      <div class="brandBox wow animate__animated animate__fadeInDown">
        <!-- 展示英塔品牌 -->
        <div class="inta">
          <div class="botText">
            <div class="brandLogo">
              <img :src="brandImgurl" alt="" />
            </div>
          </div>
        </div>
        <div class="item" v-for="(item, index) in brandList" :key="index">
          <div class="botText">
            <div class="titBox">
              <div class="brandTit">{{ item.moudle }}</div>
            </div>

            <div class="brandDetail" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 品牌产品说明 -->

    <div class="description" id="ppcpjs">
      <div class="partContent">
        <div @click="toInTa" style="cursor: pointer;" class="partName">
          英塔网
        </div>
        <div class="tabChange wow animate__animated animate__pulse">
          <div
            v-for="(item, index) in tabList"
            :class="tabIndex == index ? 'tabItem active' : 'tabItem'"
            :key="index"
            @mousemove="changeTab(item.value)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="mainContent">
          <div
            v-if="tabIndex == 0"
            class="partIntro zero wow animate__animated animate__fadeInLeft"
          >
            <div
              class="intro"
              v-for="(item, index) in introList_func"
              :key="index"
            >
              <div class="icon">
                <div class="icon-coin"></div>
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
          <div
            v-if="tabIndex == 1"
            class="partIntro one wow animate__animated animate__fadeInRight"
          >
            <div
              class="intro"
              v-for="(item, index) in introList_logic"
              :key="index"
            >
              <div class="icon">
                <div class="icon-coin"></div>
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 中小企业数字大脑 -->
    <div class="digitalBox">
      <div class="partName">中小企业数字大脑</div>
      <div
        class="digitalBrain wow animate__animated animate__heartBeat"
        id="cpzscj"
      >
        <div class="bannerBox">
          <div class="experience" @click="toLook">
            <div class="text">立即查看</div>
            <div class="icon">
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 英塔云 -->
    <div class="intaCloud" id="yty">
      <div class="cloudBox">
        <div class="partName wow animate__animated animate__bounce">
          英塔云
        </div>
        <div class="subName">
          英塔云，让计算资源触手可及，助力企业高效发展
        </div>

        <div class="cloudContent">
          <div class="big wow animate__animated animate__fadeInLeft"></div>
          <div class="small wow animate__animated animate__fadeInRight">
            <div
              class="item"
              v-for="(item, index) in cloudContent"
              :key="index"
            >
              <div class="name">{{ item.title }}</div>
              <div class="con" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 品牌产品推广 -->

    <div class="promotion">
      <div class="productPromotion" id="ppcptg">
        <div class="proTit">
          <div>
            <div class="subTit">
              <div
                :class="promotionIndex == index ? 'subIte active' : 'subIte'"
                v-for="(item, index) in promotionList"
                :key="index"
                @mousemove="changePromotion(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="article">
          <div
            v-for="(item, index) in promotionList"
            :key="index"
            v-show="promotionIndex == index"
            class="a"
            :class="
              promotionIndex % 2 == 0
                ? 'articleBox wow animate__animated animate__fadeInLeft'
                : 'articleBox wow animate__animated animate__fadeInRight'
            "
          >
            <div class="img">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="right">
              <div v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新闻资讯 -->

    <div class="newsBox">
      <div class="news">
        <div class="title wow animate__animated animate__zoomIn">
          新闻资讯
        </div>
        <div
          v-if="newsIndex == 0"
          class="newsBox wow animate__animated animate__flipInX"
        >
          <div
            class="item"
            v-for="(item, index) in newsListOne"
            :key="index"
            @click="link(item.title, item.content, item.time)"
          >
            <div class="leftImg">
              <img class="img" :src="item.url" alt="" />
            </div>
            <div class="rightText">
              <div class="headline">{{ item.title }}</div>
              <div class="content">
                {{ item.desc }}
              </div>
              <div class="date">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="newsIndex == 1"
          class="newsBox wow animate__animated animate__flipInY"
        >
          <div
            class="item"
            v-for="(item, index) in newsListTwo"
            :key="index"
            @click="link(item.title, item.content, item.time)"
          >
            <div class="leftImg">
              <img class="img" :src="item.url" alt="" />
            </div>
            <div class="rightText">
              <div class="headline">{{ item.title }}</div>
              <div class="content">
                {{ item.desc }}
              </div>
              <div class="date">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="newsIndex == 2"
          class="newsBox wow animate__animated animate__flipInX"
        >
          <div
            class="item"
            v-for="(item, index) in newsListThree"
            :key="index"
            @click="link(item.title, item.content, item.time)"
          >
            <div class="leftImg">
              <img class="img" :src="item.url" alt="" />
            </div>
            <div class="rightText">
              <div class="headline">{{ item.title }}</div>
              <div class="content">
                {{ item.desc }}
              </div>
              <div class="date">{{ item.time }}</div>
            </div>
          </div>
        </div>

        <div
          v-if="newsIndex == 3"
          class="newsBox wow animate__animated animate__flipInY"
        >
          <div
            class="item"
            v-for="(item, index) in newsList"
            :key="index"
            @click="link(item.title, item.url, item.content)"
          >
            <div class="leftImg">
              <img class="img" :src="item.url" alt="" />
            </div>
            <div class="rightText">
              <div class="headline">{{ item.title }}</div>
              <div class="content">
                {{ item.desc }}
              </div>
              <div class="date">{{ item.time }}</div>
            </div>
          </div>
        </div>

        <div class="pagegition">
          <div
            :class="newsIndex == index ? 'ite active' : 'ite'"
            v-for="(item, index) in newsPagination"
            :key="index"
            @mouseover="changeNews(index)"
          ></div>
        </div>

        <div class="more" @click="toMoreNews">
          <p style="margin-right: 5px;">查看更多</p>
          <p><i class="el-icon-right"></i></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIntamhwProductinduceList } from "@/api/index2";
import { getIntamhwNewsList } from "@/api/news";
import { getIntamhwIntawList } from "@/api/intaw";
import { WOW } from "wowjs";

export default {
  name: "index",
  data() {
    return {
      cloudContent: [],
      companyContent: {},
      newsList: [],
      newsListOne: [],
      newsListTwo: [],
      newsListThree: [],

      newsPagination: [
        {
          value: 0,
        },
        {
          value: 1,
        },
        {
          value: 2,
        },
      ],

      newsIndex: 0,

      tabList: [
        {
          value: 0,
          label: "逻辑介绍",
        },
        {
          value: 1,
          label: "功能介绍",
        },
      ],
      tabIndex: 1,
      promotionList: [],
      promotionIndex: 0,
      brandList: [],
      brandImgurl: require("../../assets/img/ytLogo.png"),
      introList_func: [],
      introList_logic: [],
      introList_show: [],
      blockFirst: [
        {
          url: require("../../assets/img/index/gsjs.png"),
          title: "公司介绍",
          content:
            "英塔公司是一个有多家公司所组成的生态集群公司，它以电子商务网站平台为中心，由独立公司进行业务经营的公司群体，其目标是为企业和自然人所组成的纳税人群体，提供各类力所能及的智能化大数据和信息化服务，我们立足于安徽，逐步向全国拓展。",
        },
      ],
      blockThree: [
        {
          url: require("../../assets/img/index/ytw.png"),
          icon: require("../../assets/img/index/icon.png"),
          title: "英塔网 (https.//www.intaw.com)",
        },
      ],
      blockSecond: [],
      intawList: [],
    };
  },
  created() {
    this.getIntamhwProductinduceList();
    this.getIntamhwNewsList();
    this.getIntamhwIntawList();
    if (this.$route.hash) {
      this.$nextTick(() => {
        location.hash = this.$route.hash;
      });
    }
  },

  //初始化wow.js
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 20, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },

  methods: {
    // 打开英塔网
    toInTa() {
      window.open("https://www.intaw.com", "_blank");
    },
    toLook() {
      console.log(111);
      this.$message({
        message: "敬请期待",
        type: "success",
        duration: 2000,
      });
    },
    //跳转到视频介绍
    toVideo() {
      this.$router.push({
        path: "/video",
        query: {
          videourl: this.companyContent.videoUrl,
        },
      });
    },

    //查看更多新闻
    toMoreNews() {
      this.$router.push({
        path: "/news",
        query: {},
      });
    },
    // 品牌产品说明切换
    changeTab(val) {
      this.tabIndex = val;
    },

    // 品牌推广、产品推广说明切换

    changePromotion(val) {
      this.promotionIndex = val;
    },

    //切换新闻

    changeNews(index) {
      this.newsIndex = index;
    },

    getIntamhwProductinduceList() {
      getIntamhwProductinduceList().then((res) => {
        if (res.status == 200) {
          this.blockSecond = res.data;
        } else {
        }
      });
    },

    getIntamhwNewsList() {
      getIntamhwNewsList().then((res) => {
        if (res.status == 200) {
          this.newsList = res.data;
          this.newsListOne = this.newsList.slice(0, 4);
          this.newsListTwo = this.newsList.slice(4, 8);
          this.newsListThree = this.newsList.slice(8, 12);
        } else {
          this.newsList = [];
        }
      });
    },

    getIntamhwIntawList() {
      getIntamhwIntawList().then((res) => {
        if (res.status == 200) {
          this.intawList = res.data;
          for (let i = 0; i < this.intawList.length; i++) {
            let obj = this.intawList[i];
            if (
              obj.moudle == "品牌定位" ||
              obj.moudle == "品牌内涵" ||
              obj.moudle == "品牌外延"
            ) {
              this.brandList.push(obj);
            } else if (obj.moudle == "品牌推广" || obj.moudle == "产品推广") {
              this.promotionList.push(obj);
            } else if (obj.moudle == "英塔云") {
              this.cloudContent.push(obj);
            } else if (obj.moudle == "产品逻辑介绍") {
              this.introList_func.push(obj);
            } else if (obj.moudle == "产品功能介绍") {
              this.introList_logic.push(obj);
            } else if (obj.moudle == "产品展现形式") {
              this.introList_show.push(obj);
            } else if (obj.moudle == "公司介绍") {
              this.companyContent = obj;
            }
          }
        } else {
        }
      });
    },

    link(title, content, time) {
      let routeData = this.$router.resolve({
        path: "/newscontent",
        query: {
          linkTitle: title,
          content: content,
          time: time,
        },
      });

      window.open(routeData.href, "_blank");
    },
    getoIntaw(title, content, videoUrl) {
      this.$router.push({
        path: "/intawContent",
        query: {
          title: title,
          content: content,
          videoUrl: videoUrl,
        },
      });
    },
    goproduct(index) {
      this.$router.push({ path: "/product", query: { index: index } });
    },
  },
};
</script>

<style lang="scss" scoped>
.wow {
  animation-delay: 0.5s;
}
.home {
  width: 100%;
  height: 100%;
  background: #ffffff;
}

//公司简介
.index {
  width: 1200px;
  margin: 0px auto;

  .one {
    margin: 50px 0;
    .one_B {
      width: 100%;
      height: 474px;
      display: flex;
      justify-content: space-between;
      .one_lift {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;

        .name {
          margin: 20px 0 !important;
          font-weight: bold;
          font-size: 26px;
          color: #181818;
        }
        .sdp {
          width: 500px;
          letter-spacing: 1px;
          line-height: 25px;
          margin: 0 !important;
          span {
            font-weight: 500;
            font-size: 15px;
            color: #181818;
          }
        }
      }
      .one_right {
        background: url("../../assets/img/index/company_bg.png") no-repeat
          center center;
        width: 763px;
        height: 474px;
        cursor: pointer;
        position: relative;

        .btn {
          position: absolute;
          top: 30%;
          left: 38%;
          width: 80px;
          height: 80px;
          background: url("../../assets/img/btn.png") no-repeat center center /
            100% 100%;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

//品牌定位
.brand {
  width: 100%;
  padding: 100px 0;
  background: #f7fafd;
  .brandBox {
    width: 1200px;
    margin: 0px auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .inta {
      width: 23%;
      height: 350px;
      padding: 25px;
      box-sizing: border-box;
      cursor: pointer;
      background: #fff;
      .botText {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .brandLogo {
          width: 138px;
          height: 48px;
          img {
            width: 138px;
            height: 48px;
          }
        }
      }
    }

    .item {
      width: 23%;
      height: 350px;
      padding: 25px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      cursor: pointer;
      background: #ffffff;
      transition: all 0.3s;
      &:hover {
        background: #076ce0;
        transform: scale(1.1);
        .botText {
          .titBox {
            .brandTit {
              color: #fff !important;
              border-bottom: 3px solid #fff;
            }
          }

          .brandDetail {
            color: #fff !important ;
          }
        }

        .more {
          color: #fff;
        }
      }

      .botText {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

        .titBox {
          display: flex;
          .brandTit {
            width: 90px;
            height: 50px;
            line-height: 50px;
            font-weight: bold;
            font-size: 22px;
            color: #181818;
            border-bottom: 3px solid #181818;
          }
        }

        .brandDetail {
          margin-top: 30px;
          font-weight: 400;
          font-size: 16px;
          color: #181818;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 将对象作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 限制在一个块元素显示的文本的行数 */
          /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
          -webkit-line-clamp: 7;
          /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

// 品牌产品说明

.description {
  width: 100%;
  padding: 50px 0;
  background: #fff;
  .partContent {
    width: 1200px;
    margin: 0px auto;
    height: auto;
    .partName {
      text-align: center;
      font-weight: bold;
      font-size: 38px;
      color: #181818;
      margin-bottom: 10px;
    }

    .tabChange {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .tabItem {
        width: 180px;
        height: 55px;
        margin: 0 40px;
        font-weight: 500;
        font-size: 20px;
        color: #181818;
        text-align: center;
        line-height: 60px;
        color: #181818;
        cursor: pointer;
        &.active {
          color: #076ce0 !important;
          border-bottom: 2px solid #076ce0 !important;
        }
        &:hover {
          color: #076ce0 !important;
          border-bottom: 2px solid #076ce0 !important;
        }
      }
    }

    .mainContent {
      width: 100%;
      padding: 20px 0;
      .partIntro {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &.zero {
          .intro {
            &:nth-of-type(1) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/fzqs_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(2) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/gousi_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(3) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/hz_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(4) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/gy_.png") no-repeat
                    center center;
                }
              }
            }

            &:hover {
              background: #f0f0f0;
              transform: scale(0.9);

              &:nth-of-type(1) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/fzqs.png") no-repeat
                      center center;
                  }
                }
              }
              &:nth-of-type(2) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/gousi.png")
                      no-repeat center center;
                  }
                }
              }
              &:nth-of-type(3) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/hz.png") no-repeat
                      center center;
                  }
                }
              }
              &:nth-of-type(4) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/gy.png") no-repeat
                      center center;
                  }
                }
              }

              .title {
                color: #076ce0;
              }
            }
          }
        }
        &.one {
          .intro {
            &:nth-of-type(1) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/yhrk_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(2) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/shsq_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(3) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/dmx_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(4) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/szhzx_.png") no-repeat
                    center center;
                }
              }
            }
            &:nth-of-type(5) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/qyszdn_.png")
                    no-repeat center center;
                }
              }
            }
            &:nth-of-type(6) {
              .icon {
                .icon-coin {
                  background: url("../../assets/img/index/fqyjy_.png") no-repeat
                    center center;
                }
              }
            }

            &:hover {
              background: #f0f0f0;
              transform: scale(0.9);

              &:nth-of-type(1) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/yhrk.png") no-repeat
                      center center;
                  }
                }
              }
              &:nth-of-type(2) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/shsq.png") no-repeat
                      center center;
                  }
                }
              }
              &:nth-of-type(3) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/dmx.png") no-repeat
                      center center;
                  }
                }
              }
              &:nth-of-type(4) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/szhzx.png")
                      no-repeat center center;
                  }
                }
              }
              &:nth-of-type(5) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/qyszdn.png")
                      no-repeat center center;
                  }
                }
              }
              &:nth-of-type(6) {
                .icon {
                  .icon-coin {
                    background: url("../../assets/img/index/fqyjy.png")
                      no-repeat center center;
                  }
                }
              }

              .title {
                color: #076ce0;
              }
            }
          }
        }

        .intro {
          flex: 1;
          width: calc(100% / 3);
          min-width: calc(100% / 3);
          max-width: calc(100% / 3);
          height: 360px;
          padding: 20px;
          box-sizing: border-box;
          cursor: pointer;
          transition-property: transform; /* 这里指定了transform属性作为过渡效果 */
          transition-duration: 0.3s; /* 过渡持续时间为0.3秒 */

          .icon {
            display: flex;
            justify-content: center;
            .icon-coin {
              width: 120px !important;
              height: 120px !important;
              margin-bottom: 30px;
            }
          }

          .title {
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            color: #181818;
            margin-bottom: 10px;
          }

          .content {
            font-weight: 400;
            font-size: 14px;
            color: #181818;
            line-height: 25px;
            overflow: hidden; //溢出隐藏
            text-overflow: ellipsis; //省略号
            display: -webkit-box; //
            -webkit-box-orient: vertical; //设置弹性盒子的子元素的排列方式
            -webkit-line-clamp: 5; //设置显示文本的行数
          }
        }
      }
    }
  }
}

// 中小企业数字大脑

.digitalBox {
  .partName {
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    color: #181818;
    margin-bottom: 50px;
  }

  .digitalBrain {
    width: 100%;
    height: 480px;
    background: url("../../assets/img/index/szdn.png") no-repeat center center;

    .bannerBox {
      width: 1200px;
      height: 480px;
      margin: 0 auto;
      position: relative;
      .experience {
        cursor: pointer;
        position: absolute;
        bottom: 100px;
        width: 600px;
        height: 68px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border: 2px solid #076ce0;
        color: #333;
        font-size: 19px;
        font-weight: bold;

        /* 遮罩层 */
        &::before {
          content: "";
          width: 0;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          background-color: #076ce0;
          /* 添加斜切，让元素倾斜45度 */
          transform: skewX(0deg);
          transition: all 0.5s;
          /* 降低悬浮遮罩透明度 */
          opacity: 0.5;
        }

        /* 添加悬浮效果：未悬浮时width为0,不显示，悬浮时添加宽度*/
        &:hover::before {
          width: 100%;
          /* 调整悬浮时的字体颜色 */
          color: #fff;
        }
      }
    }
  }
}

// 英塔云
.intaCloud {
  width: 100%;
  height: auto;
  background: #f7fafd;
  .cloudBox {
    width: 1200px;
    margin: 0px auto;
    padding: 50px 0;
    .partName {
      text-align: center;
      font-weight: bold;
      font-size: 38px;
      color: #181818;
    }
    .subName {
      padding: 20px 0;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      color: #6e6d6d;
    }

    .cloudContent {
      width: 100%;
      height: 420px;
      display: flex;
      .big {
        width: 380px;
        height: 420px;
        margin-right: 20px;
        background: url("../../assets/img/index/privateCloud.png") no-repeat
          center center / 100% 100%;
      }
      .small {
        width: calc(100% - 400px);
        height: 380px;
        display: flex;
        justify-content: space-between;
        .item {
          width: calc((100% - 40px) / 3);
          height: 420px;
          background: #fff;
          cursor: pointer;
          padding: 20px;
          box-sizing: border-box;
          &:hover {
            background-color: #076ce0;
            .name {
              color: #fff;
              border-bottom: 2px solid #fff;
            }
            .con {
              color: #fff;
            }
          }
          .name {
            width: 90px;
            height: 50px;
            line-height: 50px;
            font-weight: bold;
            font-size: 18px;
            color: #181818;
            border-bottom: 2px solid #181818;
          }
          .con {
            margin-top: 30px;
            font-weight: 400;
            font-size: 16px;
            color: #181818;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 将对象作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 限制在一个块元素显示的文本的行数 */
            /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
            -webkit-line-clamp: 7;
            /* 设置或检索伸缩盒对象的子元素的排列方式 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}

// 品牌产品推广
.promotion {
  background: #fff;
  padding: 50px 0;
  .productPromotion {
    width: 1200px;
    margin: 0px auto;

    .proTit {
      width: 100%;
      display: flex;
      justify-content: center;

      .subTit {
        width: 100%;
        display: flex;
        align-items: center;
        .subIte {
          width: auto;
          height: 60px;
          margin: 0 40px;
          font-size: 24px;
          color: #181818;
          line-height: 60px;
          color: #181818;
          font-weight: 700;
          cursor: pointer;
          &.active {
            color: #076ce0 !important;
            border-bottom: 3px solid #076ce0 !important;
          }
          &:hover {
            color: #076ce0 !important;
            border-bottom: 3px solid #076ce0 !important;
          }
        }
      }
    }

    .article {
      margin-top: 50px;

      .articleBox {
        display: flex;
      }

      .img {
        margin-right: 40px;
        > img {
          height: 340px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > div {
          font-weight: 400;
          font-size: 18px;
          color: #181818;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 将对象作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 限制在一个块元素显示的文本的行数 */
          /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
          -webkit-line-clamp: 7;
          /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

//联系我们
.contact {
  width: 100%;
  padding: 100px 0;
  background: #f7fafd;

  .contactCon {
    width: 1200px;
    margin: 0px auto;
    display: flex;

    .compInfo {
      width: 600px;
      height: 480px;
      padding: 40px;
      background: #3d404a;
      box-sizing: border-box;
      .companyName {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #ffffff;
        margin-bottom: 40px;
      }

      .infoItem {
        margin-bottom: 20px;
        display: flex;
        .icon {
          width: 48px;
          height: 48px;
          background: #ffffff;
          border-radius: 50%;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 20px;
          .nameInfo {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
          }

          .textInfo {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
          }
        }
      }
    }

    .contactInfo {
      width: 600px;
      height: 480px;
      padding: 40px;
      background: #fff;
      box-sizing: border-box;
      .pleace {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #076ce0;
        margin-bottom: 40px;
      }

      //表单信息

      .formInfo {
      }
    }
  }
}

//新闻资讯

.newsBox {
  width: 100%;
  background: #f7fafd;
  .news {
    width: 1200px;
    margin: 0px auto;
    padding: 50px 0;

    .title {
      text-align: center;
      font-weight: bold;
      font-size: 38px;
      color: #181818;
      margin-bottom: 50px;
    }

    .newsBox {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 580px;
        height: 160px;
        cursor: pointer;
        margin-bottom: 40px;
        display: flex;
        .leftImg {
          width: 260px;
          height: 160px;
          .img {
            width: 260px;
            height: 160px;
          }
        }

        .rightText {
          margin-left: 20px;
          width: calc(100% - 280px);
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .headline {
            width: 100%;
            font-weight: bold;
            font-size: 22px;
            color: #181818;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .content {
            font-weight: 400;
            font-size: 16px;
            color: #181818;
            line-height: 26px;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 将对象作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 限制在一个块元素显示的文本的行数 */
            /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
            -webkit-line-clamp: 2;
            /* 设置或检索伸缩盒对象的子元素的排列方式 */
            -webkit-box-orient: vertical;
          }
          .date {
            font-weight: 400;
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }

    .pagegition {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .ite {
        width: 36px;
        height: 5px;
        background: #f0f0f0;
        border-radius: 3px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          background: #076ce0 !important;
        }
        &.active {
          background: #076ce0;
        }
      }
    }

    .more {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      color: #181818;
      cursor: pointer;

      &:hover {
        color: #076ce0;
      }
    }
  }
}
</style>
