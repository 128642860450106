import { SET_ACTIVE_LINK_NAME } from "./constants";

export default {
  //管理状态

  namespaced: true,
  state: {
    activeLinkName: "",
  },
  mutations: {
    [SET_ACTIVE_LINK_NAME](state, activeLinkName) {
      state.activeLinkName = activeLinkName;
    },
  },
  actions: {},
  getters: {},
};
