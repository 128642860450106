import axios from "./axios";
//获取产品介绍List
export const getIntamhwProductinduceList = (params = {}) =>
  axios.post("/intamhwMenu/getIntamhwProductinduceList", null, { params });

//联系我们
export const saveContactUs = (params = {}) =>
  axios.post("/intamhwMenu/contact/insertContact", null, { params });

//获取验证码
export const getCode = (params = {}) =>
  axios.get("/captchaImage", null, { params });
