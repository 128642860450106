import axios from './axios';
//获取新闻List(前四条)
export const getIntamhwNewsList = (params = {}) =>
    axios.post('/intamhwMenu/getIntamhwNewsList',null,  { params })

//获取新闻List(全部)
export const getIntamhwNewsListAll = (params = {}) =>
    axios.post('/intamhwMenu/getIntamhwNewsListAll',null,  { params })



