import Vue from "vue";
import Vuex from "vuex";
import common from "./common/index.js";
export * from "./common/constants.js";
// Vue.use(Vuex);
const store = new Vuex.Store({
  ...common,
  modules: {},
  plugins: [],
});

export default store;
