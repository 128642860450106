<!-- 模板 -->
<template>
  <div class="root">
    <div class="but">
      <el-button
        size="small"
        class="el-icon-back"
        type="primary"
        @click="toreturn()"
      >
        返回
      </el-button>
    </div>
    <div class="box" @contextmenu.prevent="menuPlayer()">
      <h2>
        {{ spList.title }}
      </h2>

      <div class="videoBox">
        <video
          id="video"
          :src="videoUrl"
          autoplay="true"
          controls="false"
          poster="images.jpg"
          preload="auto"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
          style="object-fit:fill"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      mediaPath: "",
      spList: {
        title: "英塔公司简介",
        content: "英塔公司简介",
      }, //视频
      videoUrl: "",
    };
  },
  props: [],
  components: {},
  computed: {},
  filters: {},
  created() {
    this.videoUrl = this.$route.query.videourl;
    console.log(this.$route.query);
  },
  mounted() {},
  methods: {
    //返回上一层
    toreturn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.root {
  width: 100%;
  background-color: #f7fafd;
  .but {
    width: 1200px;
    margin: 0px auto;
    padding-top: 50px;
  }

  .box {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 100px;
    h2 {
      text-align: center;
      padding-bottom: 40px;
      font-size: 40px;
    }
    .videoBox {
      width: 100%;
      height: 700px;
      #video {
        width: 100%;
        height: 700px;
      }
    }
  }
}
</style>
