<template>
  <div class="footerEnd">
    <div class="footertop">
      <p>运营公司：合肥英塔信息技术有限公司</p>
      &nbsp;&nbsp;&nbsp;

      <p>网址:http://www.inta.cn/</p>
      &nbsp;&nbsp;&nbsp;

      <p>电话:400-188-0805</p>
      &nbsp;&nbsp;&nbsp;
      <p>地址:天通路16号软件园5号楼4楼</p>
      <br />
      <span style="display:inline-block;margin-top: 5px;"
        >Copyright©2022 inta.cn. All Rights Reserved.</span
      >
      <br />
      <a
        style="display:inline-block; color:#fff;text-decoration:none;margin-top: 5px;"
        href="https://beian.miit.gov.cn/"
        >备案号:皖ICP备07001247号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "footerEnd",
};
</script>

<style lang="scss" scoped>
.footerEnd {
  height: 100px;
  min-width: 1200px;
  background-color: #011532;
  color: #fff;
  padding: 20px 0px;
  .footertop {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    p {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      line-height: 25px;
    }
  }
  .footerwz {
    text-align: center;
    font-size: 12px;
    span {
      display: inline-block;
      margin-top: 8px;
    }
  }
}
</style>
