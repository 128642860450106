<template>
  <div>
    <div class="brand">
      <div class="title wow animate__animated animate__bounceInDown">
        数字研究
      </div>
      <div class="brandBox wow animate__animated animate__bounceInUp">
        <div class="item" v-for="(item, index) in brandlist" :key="index">
          <div class="botText">
            <div class="brandTit">{{ item.name }}</div>
            <div class="brandDetail">
              <div class="name">{{ item.name }}</div>
              <div class="more">
                <span>了解详情</span>
                <span><i class="el-icon-right"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIntamhwNewsListAll } from "@/api/news";
import { WOW } from "wowjs";
export default {
  name: "brand",
  data() {
    return {
      imgurl: require("../../assets/img/index/ytw.png"),
      brandlist: [
        {
          name: "数字定位",
          imgurl: require("../../assets/img/index/ytw.png"),
        },
        {
          name: "数字内涵",
          imgurl: require("../../assets/img/index/ytw.png"),
        },
        {
          name: "数字外延",
          imgurl: require("../../assets/img/index/ytw.png"),
        },
      ],
      activeIndex: 0,
    };
  },
  created() {
    this.getIntamhwNewsListAll();
  },
  //初始化wow.js
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  methods: {
    getIntamhwNewsListAll() {
      getIntamhwNewsListAll().then((res) => {
        if (res.status == 200) {
          // this.brandlist = res.data;
          // this.brandlist = this.brandlist.splice(0, 4);
        } else {
          alert("获取新闻异常！");
        }
      });
    },
    link(title, url, url1, url2, url3, time, p1, p2, p3) {
      this.$router.push({
        path: "/newscontent",
        query: {
          linktitle: title,
          imgurl: url,
          imgurl1: url1,
          imgurl2: url2,
          imgurl3: url3,
          time: time,
        },
      });
      sessionStorage.setItem("name", p1);
      sessionStorage.setItem("name1", p2);
      sessionStorage.setItem("name2", p3);
    },
  },
};
</script>

<style lang="scss" scoped>
.brand {
  width: 1200px;
  margin: 0px auto;
  padding: 40px 0;
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #181818;
    margin-bottom: 50px;
  }

  .brandBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 320px;
      height: 370px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      cursor: pointer;
      background: url("../../assets/img/index/ytx.jpg") no-repeat center / 100%
        100%;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;

      &:hover {
        .brandTit {
          display: none;
        }

        .brandDetail {
          display: block !important;
        }
      }

      .botText {
        width: 320px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .brandTit {
          width: 100%;
          height: 60px;
          text-align: center;
          line-height: 60px;
          background: #c1c1bd;
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
        }

        .brandDetail {
          width: 100%;
          height: 86px;
          display: none;
          background: #9e9f9b;
          padding: 0 20px;
          box-sizing: border-box;
          .name {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
          }
          .more {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
