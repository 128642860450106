<template>
  <div class="company">
    <div class="one">
      <div class="one_B">
        <div class="one_lift wow animate__animated animate__fadeInLeft">
          <p class="tit">公司介绍</p>
          <p class="name">{{ companyContent.title }}</p>
          <p class="sdp" v-html="companyContent.content"></p>
        </div>
        <div
          @click="toVideo"
          class="one_right wow animate__animated animate__fadeInRight"
        >
          <div class="btn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIntamhwIntawList } from "@/api/intaw";
import { WOW } from "wowjs";
export default {
  name: "company",
  data() {
    return {
      intawList: [],
      companyContent: {},
    };
  },
  created() {
    this.getIntamhwCompanyList();
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  methods: {
    //跳转到视频介绍
    toVideo() {
      this.$router.push({
        path: "/video",
        query: {
          videourl: this.companyContent.videoUrl,
        },
      });
    },

    //获取公司介绍
    getIntamhwCompanyList() {
      getIntamhwIntawList().then((res) => {
        if (res.status == 200) {
          this.intawList = res.data;
          for (let i = 0; i < this.intawList.length; i++) {
            let obj = this.intawList[i];
            if (obj.moudle == "公司介绍") {
              this.companyContent = obj;
            }
          }
        } else {
          alert("获取公司介绍异常！");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  width: 1200px;
  margin: 0px auto;
  background: #fff;

  //公司简介

  .one {
    margin: 100px 0;
    .one_B {
      width: 100%;
      height: 474px;
      display: flex;
      justify-content: space-between;
      .one_lift {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;

        .tit {
          margin: 20px 0 !important;
          font-weight: bold;
          font-size: 40px;
          color: #181818;
        }

        .name {
          margin: 20px 0 !important;
          font-weight: bold;
          font-size: 24px;
          color: #181818;
        }
        .sdp {
          width: 500px;
          letter-spacing: 1px;
          line-height: 25px;
          margin: 0 !important;
          span {
            font-weight: 500;
            font-size: 16px;
            color: #181818;
          }
        }
      }
      .one_right {
        background: url("../../assets/img/index/company_bg.png") no-repeat
          center center;
        width: 700px;
        height: 474px;
        cursor: pointer;
        position: relative;
        .btn {
          position: absolute;
          top: 30%;
          left: 38%;
          width: 80px;
          height: 80px;
          background: url("../../assets/img/btn.png") no-repeat center center /
            100% 100%;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
</style>
