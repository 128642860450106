<template>
  <div>
    <div class="service">
      <div>
        <div><h1>服务对象 /<span> SERVE</span></h1></div>
        <el-row>
          <el-col>
            <div class="left1">
              <img src="" class="image">
            </div>
            <div class="right1">
              <p>安徽省税务局</p>
            </div>
          </el-col>
        </el-row>
        <br><br>
        <el-row>
          <el-col>
            <div class="left2">
              <img src="" class="image">
            </div>
            <div class="right2">
              <p>各类纳税人企业</p>
            </div>
          </el-col>
        </el-row>
        <br><br>
        <el-row>
          <el-col>
            <div class="left1">
              <img src="" class="image">
            </div>
            <div class="right1">
              <p>各类拥有房产的用户</p>
            </div>
          </el-col>
        </el-row>
        <br><br>
        <el-row>
          <el-col>
            <div class="left2">
              <img src="" class="image">
            </div>
            <div class="right2">
              <p>各类企业决策领导</p>
            </div>
          </el-col>
        </el-row>
        <br><br>
      </div>
    </div>
    <footer-end></footer-end>
  </div>
</template>

<script>
export default {
  name: 'service'
}
</script>

<style lang="scss" scoped>
.service{
  width: 1200px;
  margin: 0px auto;
  h1{
    text-align: center;
    font-size: 34px;
    margin-top: 90px;
    margin-bottom: 50px;
    span{
      color: rgb(179, 179, 179);
      font-size: 34px;
      font-weight: 700;
    }
  }
  .left1{
    width:60%;
    height:250px;
    background-color: #eee;
    float:left;
    z-index: 99;
    .image{
      width: 100%;
      height: 100%;
      border: 2px solid #7cb6ca;
    }
  }
  .right1{
    width:60%;
    height:250px;
    background-color: #fff;
    float:right;
    z-index: -99;
    margin-top:-200px;
    border: 2px solid #7cb6ca;
    p{
      padding: 0px;
      line-height: 28px;
      font-size: 16px;
      text-indent: 2em;
    }
  }
  .left2{
    width:60%;
    height:250px;
    background-color: #eee;
    float:right;
    z-index: -99;
    .image{
      width: 100%;
      height: 100%;
      border: 2px solid #7cb6ca;
    }
  }
  .right2{
    width:60%;
    height:250px;
    background-color: #fff;
    float:left;
    z-index: 99;
    margin-top:-200px;
    border: 2px solid #7cb6ca;
    p{
      padding: 0px;
      line-height: 28px;
      font-size: 16px;
      text-indent: 2em;
    }
  }
}
</style>
