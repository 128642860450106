<template>
  <div>
    <div class="product">
      <div>
        <div>
          <h1>产品介绍 /<span> PRODUCT</span></h1>
        </div>
        <div class="btnwrap">
          <el-button v-for="(item, index) in companylist" :key="index" @click="goflag(index,item.companyId)"  :class="flag == index ? 'btnbg' : ''">{{item.title}}</el-button>
        </div>
        <el-tabs
                @tab-click="handleClick"
                v-for="(item, index) in companylist"
                v-show="flag == index"
        >
            <el-tab-pane    v-for="(item2, index2) in productlist" :key="index2" :label="item2.label" v-html="item2.content">

            </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {getIntamhwCompanyList,getIntamhwProductList} from '@/api/company';
export default {
  name: "product",
  data() {
    return {
      activeName: "first",
      // flag:this.$route.query.index,
      flag: false,
      companylist:[],
      productlist:[],
    };
  },
  created(){
    this.getIntamhwCompanyList();
    this.getIntamhwProductList(1);
  },
  methods: {
    getIntamhwCompanyList(){
      getIntamhwCompanyList().then(res => {
        if (res.status == 200) {
          this.companylist = res.data;
        } else {
          alert("获取公司异常！");
        }
      })
    },
    getIntamhwProductList(id){
      getIntamhwProductList({companyId:id}).then(res => {
        if (res.status == 200) {
          this.productlist = res.data;
        } else {
          alert("获取公司产品异常！");
        }
      })
    },
    goflag(index,companyId) {
      this.flag = index;
      this.getIntamhwProductList(companyId);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.btnbg {
  background-color: #064c8a;
  color: #fff;
}
.product {
  width: 1200px;
  margin: 0px auto;
  h1 {
    text-align: center;
    font-size: 34px;
    margin-top: 90px;
    span {
      color: rgb(179, 179, 179);
      font-size: 34px;
      font-weight: 700;
    }
  }
  .btnwrap {
    text-align: center;
    margin-bottom: 30px;
  }
  p {
    padding: 0px;
    line-height: 28px;
    font-size: 16px;
    text-indent: 2em;
  }
}
/deep/.el-tabs__content {
  position: relative;
  width: 1200px;
  img {
    margin: 0px auto;
  }
}

.eleventh {
  h1 {
    text-align: center;
    font-size: 24px;
    margin-top: 90px;
    margin-bottom: 50px;
    span {
      color: rgb(179, 179, 179);
      font-size: 24px;
      font-weight: 700;
    }
  }
  // p{
  //   padding: 0px;
  //   line-height: 30px;
  //   font-size: 16px;
  //   text-indent: 2em;
  // }
  h4 {
    border-left: 5px solid #064c8a;
    background-color: #78bcf9;
    color: #fff;
    padding: 5px 0px;
    padding-left: 10px;
    display: inline-block;
    // width: 100%;
  }
}
</style>
