import Axios from "axios";

var axios = Axios.create({
  //baseURL: "http://192.168.160.1:8091/", //测试环境url
  baseURL: 'https://api.inta.cn/',//正式环境url
  //baseURL: 'https://api-test.inta.cn/',//测式环境url
  timeout: "3000", //请求超时时间
  headers: { "X-Custom-Header": "foobar" }, //header传值，例如：Authorization
});
export default axios;
