<template>
  <div>
    <div class="newscontent">
        <div>
          <h1>{{ title }}</h1>
        </div>
        <div v-html="content"></div>
        <div v-if="videoUrl" style="padding-top: 50px;">
            <video
                    :src="videoUrl"
                    class="avatar video-avatar"
                    controls="controls"
                    width="1000"
                    height="500"
                    style="max-height: 500px;display: block"
            >
                您的浏览器不支持视频播放
            </video>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newscontent",
  data() {
    return {
      content: this.$route.query.content,
      title: this.$route.query.title,
      videoUrl: this.$route.query.videoUrl,
    };
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.demo {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid transparent;
  // border-radius: 4px;
  overflow: hidden;
  position: relative;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.newscontent {
  width: 1200px;
  margin: 0px auto;
  h1 {
    text-align: center;
    font-size: 34px;
    margin-top: 90px;
    margin-bottom: 25px;
  }
}
.text {
  line-height: 2em; //行间距（应该是2倍行间距的意思）
  text-indent: 2em; //首行缩进2个字
  font-style: normal; //   默认字体
}
.image {
  width: 1000px;
  // height: 500px;
  margin: 0px auto;
}
.content {
  // text-indent:2em;
  float: left;
}
.time {
  float: right;
  color: rgb(47, 130, 255);
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}
</style>
