import { createRouter, createWebHistory } from "vue-router";
import index from "../views/index/index.vue";
import video from "../views/index/video.vue";
import company from "../views/company/company.vue";
import product from "../views/product/product.vue";
import service from "../views/service/service.vue";
import technology from "../views/technology/technology.vue";
import partner from "../views/partner/partner.vue";
import news from "../views/news/news.vue";
import newscontent from "../views/news/newscontent.vue";
import joinUs from "../views/joinUs/joinUs.vue";
import intawContent from "../views/intaw/intawContent.vue";
import digital from "../views/digital/index.vue";
import contact from "../views/contact/contact.vue";

import store from "../store/index.js";

const routes = [
  // 首页
  {
    path: "/",
    name: "index",
    component: index,
  },
  // 首页
  {
    path: "/video",
    name: "video",
    component: video,
  },
  // 公司介绍
  {
    path: "/company",
    name: "company",
    component: company,
  },

  //联系我们

  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  // 产品介绍
  {
    path: "/product",
    name: "product",
    component: product,
  },
  // 服务对象
  {
    path: "/service",
    name: "service",
    component: service,
  },
  // 大数据技术
  {
    path: "/technology",
    name: "technology",
    component: technology,
  },
  // 合作伙伴
  {
    path: "/partner",
    name: "partner",
    component: partner,
  },
  // 图片新闻
  {
    path: "/news",
    name: "news",
    component: news,
  },
  {
    path: "/newscontent",
    name: "newscontent",
    component: newscontent,
    meta: {
      colormeta: store.state.borderBottom,
    },
  },
  {
    path: "/intawContent",
    name: "intawContent",
    component: intawContent,
  },
  // 数字研究
  {
    path: "/digital",
    name: "digital",
    component: digital,
  },
  // 求贤栏目
  {
    path: "/joinUs",
    name: "joinUs",
    component: joinUs,
  },
];

const router = createRouter({
  linkActiveClass: "active",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
