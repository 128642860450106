<template>
  <div class="navTop">
    <el-row>
      <el-col :span="24">
        <div class="top animate_cus">
          <canvas
            id="canvas"
            style="position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;"
          ></canvas>
          <div class="wrap">
            <div class="logo"></div>

            <div class="rowBox">
              <div class="row" v-if="menuList.length">
                <div
                  class="col"
                  v-for="(item, index) in menuList"
                  :key="index"
                  @click="goPage(index, item)"
                >
                  <div :class="menuUrl == item.menuUrl ? 'active' : 'link'">
                    {{ item.menuName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-show="dialogVisible" @click="pop()">
      <div class="popVisible">
        <div style="width:100%">
          <img src="../assets/img/navTop/pop.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getmenuList } from "@/api/navTop";
import { initPointLine, loop } from "@/assets/js/pointline";

export default {
  name: "navTop",
  data() {
    return {
      router_path: "",
      menuUrl: "/",
      dialogVisible: false,
      menuList: [],
    };
  },

  created() {},
  watch: {
    "$route.path": {
      handler(to, from) {
        if (to == "/company") {
          this.menuUrl = "/company";
        } else if (to == "/contact") {
          this.menuUrl = "/contact";
        } else if (to == "/news") {
          this.menuUrl = "/news";
        }
      },
      deep: true,
    },
  },
  mounted() {
    let arr = location.href.split("/");
    if (arr.length > 1) {
      this.menuUrl = "/" + arr[arr.length - 1];
    }
    this.getmenuList();
    initPointLine();
    loop();
  },
  methods: {
    goPage(index, val) {
      this.menuUrl = val.menuUrl;
      location.href = val.menuUrl;
    },
    pop() {
      this.dialogVisible = !this.dialogVisible;
    },
    getmenuList() {
      getmenuList().then((res) => {
        if (res.status == 200) {
          this.menuList = res.data;
        } else {
          this.menuList = [];
        }
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.animate_cus {
  color: #f00;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  margin: 0 0 2em;
  min-width: 1200px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
/*
.animate_cus:after {
  background: #fff;
  content: "";
  width: 50px;
  height: 850px;
  opacity: 0.5;
  position: absolute;
  top: -100px;
  left: -70px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  -webkit-transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: -10;
  animation: animation_guang 4s infinite;
  box-shadow: 0 0 56px 1px #fff;
}

@keyframes animation_guang {
  0% {
    left: 0%;
    -webkit-transition: cubic-bezier(0.19, 1, 0.22, 1);
    transition: cubic-bezier(0.19, 1, 0.22, 1);
  }
  100% {
    left: 120%;
    -webkit-transition: cubic-bezier(0.19, 1, 0.22, 1);
    transition: cubic-bezier(0.19, 1, 0.22, 1);
  }
}

 */
.active {
  display: block;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border-bottom: 2px solid #fff;
}

.popVisible {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #00000063;
  z-index: 9999;
  img {
    position: relative;
    width: 200px;
    margin: 300px auto;
    display: block;
  }
}

.navTop {
  .top {
    background: url("../assets/img/navTop/banner.png") no-repeat center;
    height: 650px;
    min-width: 1200px;
    margin: 0px auto;
    z-index: 10;
    .wrap {
      width: 1200px;
      margin: 0px auto;
      display: flex;
      justify-content: flex-start;
      .logo {
        margin-top: 5px;
        height: 48px;
        width: 138px;
        background: url("../assets/img/yt.png") no-repeat center / 100% 100%;
      }

      .rowBox {
        width: calc(100% - 138px);
        display: flex;
        align-items: center;
        justify-content: center;
        .row {
          display: flex;
          justify-content: center;
          margin: 0 !important;
          padding: 0 !important;
          .col {
            width: auto;
            margin: 0 12px;
            line-height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            .link {
              display: block;
              color: #fff;
              font-size: 16px;
              text-decoration: none;
              cursor: pointer;
              &:hover {
                border-bottom: 2px solid #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
