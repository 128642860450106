<template>
  <nav-top></nav-top>
  <router-view class="wrap" />
  <div class="customer" style="cursor: pointer">
    <div @click="toContact">
      <div class="lxwmBox">
        <div class="box"></div>
      </div>
      <div class="lxwm">联系我们</div>
    </div>
    <div class="dongchaBox">
      <div class="dongCha"></div>
    </div>

    <div class="upLoad">
      <p>英塔网</p>
      <p>皖e购APP</p>
    </div>
  </div>
  <footer-end></footer-end>
</template>

<script>
import navTop from "./components/navTop.vue";
import FooterEnd from "./components/footerEnd.vue";
export default {
  components: { navTop, FooterEnd },

  methods: {
    toContact() {
      this.$router.push({
        path: "/contact",
        query: {},
      });
    },
  },
};
</script>

<style lang="scss">
html,
body {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}
html {
  margin-right: calc(100% - 100vw);
  overflow-x: hidden;
  background: #fff !important;
}
body {
  font-family: Microsoft YaHei;
}

.wrap-outer {
  margin-left: calc(100vw - 100%);
}
.wrap {
  min-width: 1200px;
  position: relative;
}
.customer {
  z-index: 999;
  position: fixed;
  top: 65%;
  right: 3%;
  width: 100px;
  background: #fff;
  padding: 10px 0;
  border: 1px solid #439bff;

  .lxwm {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 2px solid #999;

    &:hover {
      color: #076ce0 !important;
    }
  }

  .dongchaBox {
    display: flex;
    justify-content: center;
    .dongCha {
      width: 80px;
      height: 80px;
      background: url("./assets/img/erm/upLoadUrl.png") no-repeat center / 100%
        100%;
    }
  }
  .lxwmBox {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    .box {
      width: 50px;
      height: 50px;
      background: url("./assets/img/latest/call.png") no-repeat center / 100%
        100%;
    }
  }

  .upLoad {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    margin-top: 5px;
    &:hover {
      color: #076ce0 !important;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
