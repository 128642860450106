<template>
  <div>
    <div class="joinUs">
      <div class="wow animate__animated animate__heartBeat">
        <div>
          <h1>人才招聘 /<span> JOIN</span></h1>
        </div>
      </div>
      <div
        class="wow animate__animated animate__fadeInUpBig"
        v-for="(item, index) in list"
      >
        <el-descriptions
          :column="2"
          :title="item.postName"
          border
          style="padding-top: 10px;"
        >
          <el-descriptions-item label="履历要求">
            {{ item.resume }}
          </el-descriptions-item>
          <el-descriptions-item label="年龄要求">
            {{ item.age }}
          </el-descriptions-item>
          <el-descriptions-item label="招聘人数">
            {{ item.number }}
          </el-descriptions-item>
          <el-descriptions-item label="发布时间">
            {{ item.createTime }}
          </el-descriptions-item>
          <el-descriptions-item label="招聘状态">
            <el-tag size="medium" type="success" v-if="item.status == 1"
              >招聘中</el-tag
            >
            <el-tag size="medium" type="error" v-if="item.status == 2"
              >停止招聘</el-tag
            >
          </el-descriptions-item>
          <el-descriptions-item label="薪资待遇">
            <div v-html="item.salary"></div>
          </el-descriptions-item>
          <el-descriptions-item label="技能要求">
            <div v-html="item.skill"></div>
          </el-descriptions-item>
          <el-descriptions-item label="岗位职责">
            <div v-html="item.duty"></div>
          </el-descriptions-item>
          <el-descriptions-item label="联系人">
            {{ item.contactPerson }}
          </el-descriptions-item>
          <el-descriptions-item label="联系电话">
            {{ item.phone }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
import { getIntamhwTalentRecruitmentList } from "@/api/talentRecruitment";
import { WOW } from "wowjs";
export default {
  name: "joinUs",
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  //初始化wow.js
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  methods: {
    getList() {
      getIntamhwTalentRecruitmentList().then((res) => {
        if (res.status == 200) {
          this.list = res.data;
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.joinUs {
  width: 1200px;
  margin: 0px auto;
  h1 {
    text-align: center;
    font-size: 34px;
    margin-top: 90px;
    margin-bottom: 50px;
    span {
      color: rgb(179, 179, 179);
      font-size: 34px;
      font-weight: 700;
    }
  }
  .el-descriptions {
    // /deep/.el-descriptions__body .el-descriptions__table .el-descriptions__cell{
    //   padding: 10px 10px;
    // }
    /deep/.el-descriptions__table {
      tr td:nth-child(odd) {
        // background:#ff0000;
        width: 60px;
      }
      tr td:nth-child(even) {
        // background:#ff0000;
        width: 400px;
      }
    }
    p {
      line-height: 20px;
      padding: 0px;
      margin: 8px 0px;
    }
  }
}
</style>
