import axios from './axios';
//获取公司介绍List
export const getIntamhwCompanyList = (params = {}) =>
    axios.post('/intamhwMenu/getIntamhwCompanyList',null,  { params })

//获取公司产品List
export const getIntamhwProductList = (params = {}) =>
    axios.post('/intamhwMenu/getIntamhwProductList',null,  { params })



