<template>
  <div>
    <div class="news">
      <div class="title wow animate__animated animate__zoomIn">新闻资讯</div>
      <div class="newsBox wow animate__animated animate__bounceOutDown">
        <div
          class="item"
          v-for="(item, index) in newslist"
          :key="index"
          @click="
            link(
              item.title,
              item.content,
              item.time
            )
          "
        >
          <div class="leftImg">
            <img
              class="img"
              :src="item.url"
              alt=""
            />
          </div>
          <div class="rightText">
            <div class="headline">{{ item.title }}</div>
            <div class="content">
              {{ item.desc }}
            </div>
            <div class="date">{{ item.time }}</div>
          </div>
        </div>
      </div>

      <div class="pagegition">
        <!-- <div class="ite" v-for="item in 3"></div> -->
      </div>

      <div class="more">
        <!-- <p>查看更多</p>
        <p><i class="el-icon-right"></i></p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getIntamhwNewsListAll } from "@/api/news";
import { WOW } from "wowjs";
export default {
  name: "news",
  data() {
    return {
      imgurl: require("../../assets/img/index/ytw.png"),
      newslist: [],
      activeIndex: 0,
    };
  },
  created() {
    this.getIntamhwNewsListAll();
  },
  //初始化wow.js
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  methods: {
    getIntamhwNewsListAll() {
      getIntamhwNewsListAll().then((res) => {
        if (res.status == 200) {
          this.newslist = res.data;
        } else {
          alert("获取新闻异常！");
        }
      });
    },
    link(title, content, time) {
      let routeData = this.$router.resolve({
        path: "/newscontent",
        query: {
          linkTitle: title,
          content: content,
          time: time,
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  width: 1200px;
  margin: 0px auto;
  padding: 40px 0;
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #181818;
    margin-bottom: 50px;
  }

  .newsBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 580px;
      height: 160px;
      cursor: pointer;
      margin-bottom: 40px;
      display: flex;
      .leftImg {
        width: 260px;
        height: 160px;
        .img {
          width: 260px;
          height: 160px;
        }
      }

      .rightText {
        margin-left: 20px;
        width: calc(100% - 280px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .headline {
          width: 100%;
          font-weight: bold;
          font-size: 24px;
          color: #181818;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .content {
          font-weight: 400;
          font-size: 16px;
          color: #181818;
          line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 将对象作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 限制在一个块元素显示的文本的行数 */
          /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
          -webkit-line-clamp: 2;
          /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-box-orient: vertical;
        }
        .date {
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }

  .pagegition {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .ite {
      width: 36px;
      height: 5px;
      background: #f0f0f0;
      border-radius: 3px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #181818;
    cursor: pointer;
    p:ntn-child(1) {
      margin-right: 20px;
    }
  }
}
</style>
