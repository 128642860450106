<template>
  <!-- 联系我们 -->

  <div class="contact">
      <div class="contactCon">
      <h1>
          联系我们
      </h1>
      </div>
    <div class="contactCon">
      <div class="contactInfo wow animate__animated animate__bounceInLeft">
        <div class="pleace">请留下您的联系方式</div>
        <div class="formInfo">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0"
            class="demo-ruleForm"
          >
            <el-form-item label="" prop="email">
              <el-input
                placeholder="请输入您的邮箱"
                v-model="ruleForm.email"
              ></el-input>
            </el-form-item>

            <el-form-item label="" prop="phone">
              <el-input
                placeholder="请输入您的电话"
                v-model="ruleForm.phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="content">
              <el-input
                placeholder="请留言"
                :rows="6"
                maxlength="200"
                show-word-limit
                type="textarea"
                v-model="ruleForm.content"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="code">
              <div class="code">
                <el-col :span="6">
                  <el-input
                    maxlength="4"
                    style="width:100%"
                    auto-complete="off"
                    placeholder="请输入验证码"
                    v-model="ruleForm.code"
                  >
                  </el-input>
                </el-col>
                <el-col :span="6">
                  <img
                    class="img"
                    :src="captchaPath"
                    @click="getCode()"
                    alt=""
                  />
                </el-col>
                <el-col :span="6">
                  <el-button type="primary" @click="connection()"
                    >确 定</el-button
                  >
                </el-col>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
        <div class="compInfo wow animate__animated animate__bounceInRight">
            <div class="companyName">合肥英塔信息技术有限公司</div>
            <div
                    class="infoItem"
                    v-for="(item, index) in contactInfoList"
                    :key="index"
            >
                <div class="icon">
                    <img class="img" :src="item.logoUrl" alt="" />
                </div>
                <div class="content">
                    <div class="nameInfo">{{ item.label }}：{{ item.value }}</div>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import { saveContactUs, getCode } from "@/api/index2";
import { WOW } from "wowjs";
export default {
  name: "company",

  data() {
    //验证邮箱
    var checkEmail = (rule, value, callback) => {
      if (value) {
        if (
          !/^([a-zA-Z]|[0-9])(\w|\-|\.)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
            value
          )
        ) {
          callback(new Error("邮箱格式错误"));
        } else {
          callback();
        }
      } else {
        return callback(new Error("邮箱不能为空"));
      }
    };

    /**
     * @description 校验手机号
     * @method checkPhones
     * @param rule
     * @param value
     * @param callback
     */
    var checkPhones = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        return callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      // 验证码开关
      captchaEnabled: true,
      captchaPath: "",
      contactInfoList: [
        {
          logoUrl: require("../../assets/img/contact/yx.png"),
          value: "430107108@qq.com",
          label: "邮箱",
        },
        {
          logoUrl: require("../../assets/img/contact/dh.png"),
          value: "400-188-0805",
          label: "电话",
        },
        {
          logoUrl: require("../../assets/img/contact/wz.png"),
          value: "https://www.inta.cn/",
          label: "网址",
        },
        {
          logoUrl: require("../../assets/img/contact/dz.png"),
          value: "天通路16号软件园5号楼4楼",
          label: "地址",
        },
      ],
      ruleForm: {}, //表单信息
      rules: {
        content: [{ required: true, message: "留言不能为空", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
        phone: [{ required: true, validator: checkPhones, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCode(); // 获取验证码图片
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  methods: {
    // 获取验证码
    getCode() {
      getCode().then((res) => {
        if (res.data.code == 200) {
          this.captchaEnabled =
            res.data.captchaEnabled === undefined
              ? true
              : res.data.captchaEnabled;

          if (this.captchaEnabled) {
            this.captchaPath = "data:image/gif;base64," + res.data.img;
            this.ruleForm.uuid = res.data.uuid;
          }
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    //清空表单
    resetRuleForm() {
      this.ruleForm = {};
      this.$refs["ruleForm"].resetFields();
    },
    // 联系我们的接口
    connection() {
      let params = this.ruleForm;
      console.log(this.ruleForm);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          saveContactUs(params).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "提交成功！",
                duration: 2000,
              });
              this.resetRuleForm();
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
                duration: 2000,
              });
            }
          });
          this.getCode();
        } else {
          this.getCode();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//联系我们
.contact {
  width: 100%;
  padding: 20px 0 100px 0;
  background: url(../../assets/img/contact/contactBg.jpg) no-repeat;
  background-size: contain;

  .contactCon {
    width: 1200px;
    margin: 0px auto;
    display: flex;

    .compInfo {
      width: 600px;
      height: 480px;
      padding: 40px;
      box-sizing: border-box;
      .companyName {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 40px;
      }

      .infoItem {
        margin-bottom: 20px;
        display: flex;
        .icon {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          .img {
            width: 48px;
            height: 48px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 8px;
          .nameInfo {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
          }

          .textInfo {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
    }

    .contactInfo {
      width: 600px;
      height: 480px;
      padding: 40px;
      background: #fff;
      box-sizing: border-box;
      .pleace {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #076ce0;
        margin-bottom: 40px;
      }

      //表单信息

      .formInfo {
        .code {
          display: flex;
          .img {
            cursor: pointer;
            height: 40px;
            margin: 0px 10px;
          }
        }
      }
    }
  }
}
</style>
