<template>
  <div>
    <div class="newscontent">
      <div>
        <div>
          <h1>{{ linkTitle }}</h1>
            <p style="text-align: center">时间：{{ time }}</p>
        </div>
        <el-row>
          <el-col :span="24" class="new_content text" v-html="content"></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newscontent",
  data() {
    return {
      content: this.$route.query.content,
      linkTitle: this.$route.query.linkTitle,
      time: this.$route.query.time,
    };
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  },
};
</script>
<style>
.new_content img{
    max-width: 100%;
    display: block;
    margin: auto;
}
</style>
<style lang="scss" scoped>
.new_content{
  width: 1200px;
}
.demo {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid transparent;
  // border-radius: 4px;
  overflow: hidden;
  position: relative;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.newscontent {
  width: 1200px;
  margin: 0px auto;
  h1 {
    text-align: center;
    font-size: 34px;
    margin-top: 90px;
    margin-bottom: 25px;
    span {
      color: rgb(179, 179, 179);
      font-size: 34px;
      font-weight: 700;
    }
  }
}
.text {
  line-height: 2em; //行间距（应该是2倍行间距的意思）
  text-indent: 2em; //首行缩进2个字
  font-style: normal; //   默认字体
}
.image {
  width: 1000px;
  // height: 500px;
  margin: 0px auto;
}
.content {
  // text-indent:2em;
  float: left;
}
.time {
  float: right;
  color: rgb(47, 130, 255);
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}
</style>
